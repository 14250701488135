<template>
    <Teleport to="#teleports">
        <LazyMiscModalPosition v-if="AuthStore.AuthModal" :close-handler="() => AuthStore.setAuthModal(null)">
            <LazyAuthDialogSignIn v-if="AuthStore.AuthModal.type === 'sign-in'" />
            <LazyAuthDialogSignUp v-if="AuthStore.AuthModal.type === 'sign-up'" />
            <LazyAuthDialogForgotPassword v-if="AuthStore.AuthModal.type === 'forgot-password'" />
            <LazyAuthDialogResetPasswordInit v-if="AuthStore.AuthModal.type === 'reset-password-init'" />
            <LazyAuthDialogEmailVerification
                v-if="AuthStore.AuthModal.type === 'email-verification'"
                :info="AuthStore.AuthModal.info"
            />
            <LazyAuthDialogEmailLoginStrategy v-if="AuthStore.AuthModal.type === 'email-sign-in'" />
            <LazyAuthDialogResetPasswordInit v-if="AuthStore.AuthModal.type === 'reset-password'" />
            <LazyAuthDialogAcceptTerms
                v-if="AuthStore.AuthModal.type === 'accept-terms'"
                :info="AuthStore.AuthModal.info"
            />
        </LazyMiscModalPosition>
        <LazyMiscModalPosition
            :close-handler="() => RootStore.setCouponModal(null)"
            v-if="RootStore.couponModal"
        >
            <LazyDWidgetModal v-if="RootStore.couponModal" :info="RootStore.couponModal" />
        </LazyMiscModalPosition>
        <LazyMiscModalPosition
            v-if="RootStore.genericModal"
            :close-handler="() => RootStore.setGenericModal(null)"
        >
            <LazyDialogConfirm
                v-if="RootStore.genericModal.type === 'confirm'"
                :info="RootStore.genericModal.info"
            />
            <LazyDialogNewMessage
                v-if="RootStore.genericModal.type === 'message'"
                :info="RootStore.genericModal.info"
            />
        </LazyMiscModalPosition>
        <LazyMiscModalPosition
            v-if="RootStore.dashboardModal"
            :close-handler="() => RootStore.setDashboardModal(null)"
        >
            <LazyDashDialogEmail
                v-if="RootStore.dashboardModal.type === 'email'"
                :info="RootStore.dashboardModal.info"
            />
            <LazyDashDialogPassword
                v-if="RootStore.dashboardModal.type === 'password'"
                :info="RootStore.dashboardModal.info"
            />
            <LazyDashDialogUsername
                v-if="RootStore.dashboardModal.type === 'username'"
                :info="RootStore.dashboardModal.info"
            />
        </LazyMiscModalPosition>
        <LazyMiscMobileDropdown />
    </Teleport>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

const AuthStore = useAuthStore()
const RootStore = useRootStore()
</script>

<style></style>
